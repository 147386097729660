
const LOCAL_SERVER_PORT = 8080;
const LIVE = true;
const SERVER_BASE_URL_Local = `http://localhost:${LOCAL_SERVER_PORT}/`;
const SERVER_BASE_URL_Live = 'https://tibb-scribe-server-731784686092.us-central1.run.app/';
// const SERVER_BASE_URL_Live = `http://localhost:${LOCAL_SERVER_PORT}/`;
// const APP_BASE_URL_Live = `http://localhost:3001/`;

export const BACKEND_URLS = {
    CONTACT: `${LIVE ? SERVER_BASE_URL_Live : SERVER_BASE_URL_Local}send-contact-message`,
    GET_CUSTOM_TOKEN: `${LIVE ? SERVER_BASE_URL_Live : SERVER_BASE_URL_Local}get-custom-token`,
};

export const REDIRECT_URLS = {
    APP_URL: `${LIVE ? `https://app.tibb.io/` : `http://localhost:3000/`}`
    // APP_URL: `https://app.tibb.io/`
};
